import React from "react";
import Meta from "../components/Meta/Meta";
import TheGreatMeltdown from "../components/TheGreatMeltdown/TheGreatMeltdown";

const TheGreatMeltdownPage = () => {
  return (
    <>
      <Meta />
      <TheGreatMeltdown />
    </>
  );
};

export default TheGreatMeltdownPage;
